import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { Parallax } from 'react-scroll-parallax';


import Layout from "../components/layout"
import SEO from "../components/seo"
import FormContato from "../components/formContato"


// import Bio from "../components/bio"
//import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const Home = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <>    
    <div style={{position: 'absolute', textAlign: `center`, margim: `0 auto`, padding: `0px`, height: '520px', width: '100%', backgroundColor: `white`}}>
    

    </div>
    <Layout title={siteTitle}>

      <SEO
        title="Denise Barbosa"
        keywords={[`blog`, `Denise Barbosa`, `rh`, `rh as a service`, `evolução`]}
      />
      
      
      
      <article className="post-content page-template no-image" style={{maxWidth: `1000px`}}>
      
      <p style={{marginTop: `-10px`, fontSize: `18px`, textAlign: `center`}}>
        <h3 style={{fontWeight: `200`}}>Somos especialistas em desenvolvimento<br/><b>humano</b> e <b>organizacional</b></h3>
      </p>
      
        <div className="post-content-body" style={{textAlign: `center`, margim: `0 auto`, padding: `0px`}}>

        
        <div className="row" style={{textAlign: `center`, margim: `0 auto`, padding: `0px`}} textalign="center">
            
            <div className="col-6" style={{textAlign: `center` }} textalign="center">
              <Link to="/para-voce" style={{height: `100%`, width: `100%`}}>
              <div className="card_home" style={{backgroundImage: `url(${data.voce.childImageSharp.fluid.src})`}}>
                <div style={{marginTop: "238px", display: 'block', position: 'absolute' }}>
                  <h4>&nbsp;Para Você</h4>
                </div>
                <div style={{marginTop: "305px", display: 'block', position: 'absolute', textAlign: `left`, padding: `10px`, fontSize: `16px`, lineHeight: `20px` }}>
                  Com uma abordagem 360º oferecemos alternativas e apoio para o seu desenvolvimento pessoal te ajudando a atingir seus objetivos.
                </div>
              </div>              
              </Link>
            </div>
            
            <div className="col-6" style={{textAlign: `center` }} textalign="center">
              <Link to="/para-empresa" style={{height: `100%`, width: `100%`}}>
              <div className="card_home" style={{backgroundImage: `url(${data.empresas.childImageSharp.fluid.src})`}}>
                <div style={{marginTop: "238px", display: 'block', position: 'absolute' }}>
                  <h4>&nbsp;Para Empresa</h4>
                </div>
                <div style={{marginTop: "305px", display: 'block', position: 'absolute', textAlign: `left`, padding: `10px`, fontSize: `16px`, lineHeight: `20px` }}>
                  Ofertamos soluções e apoio a organizações para o fortalecimento das relações com seu capital mais valioso: AS PESSOAS.
                </div>
              </div>              
              </Link>
            </div>
        </div>
       
      {
        /*
      
          
          <div className="post-feed">

          <article className="post-card no-image">
          <div className="post-card-content">
            <div>
            <Link to="/para-voce" className="post-card-link">
              <h3>
               Para Você
              </h3>
            </Link>
            </div>
            <div className="post-card-body">
              Encontre o melhor de você e evolua para loren ipsun xinequs etyuaso...
            </div>
            <div>
            <Link to="/para-voce" className="post-card-readmore">
              Veja Mais
            </Link>
            </div>
          </div>
          </article>

          <article className="post-card no-image">
          <div className="post-card-content">
            
            <div>
            <Link to="/para-empresa" className="post-card-link">
              <h3 >
              Para Sua Empresa 
              </h3>
            </Link>
            </div>
            <div className="post-card-body">
              Encontre o melhor de você e evolua para loren ipsun xinequs etyuaso...
            </div>
            <div>
            <Link to="/para-empresa" className="post-card-link post-card-readmore">
              Veja Mais
            </Link>
            </div>
          </div>
          </article>
          </div>

      */ }
          
          
        <br />
          {
          /*
          <div className="row" style={{borderTop: `1px solid #232323`, textAlign: 'left'}}>
            <h4>Leia no nosso Blog:</h4>
                {posts.map(({ node }) => {
                postCounter++
                return (
                  <div className="col-12">
                    <Link to={`blog/`+node.fields.slug} key={postCounter}>
                      - {node.frontmatter.title}
                    </Link>
                  </div>
                )
                })}
          </div>
          */
          }
          
          
          <hr />
          <h3 className="post-content-title">Para saber mais, me manda mensagem pelo formulário abaixo:</h3>
          <FormContato />

          

      </div>


    </article>
    </Layout>
    
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    } 
    voce: file(
      relativePath: { eq: "home-voce.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    empresas: file(
      relativePath: { eq: "home-empresas.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blog: file(
      relativePath: { eq: "home-blog.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cursos: file(
      relativePath: { eq: "home-cursos.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail 
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Home location={props.location} props data={data} {...props} />
    )}
  />
)
